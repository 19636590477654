import React from 'react';
import { useTranslation } from 'react-i18next';

const ConfidentialityPolicy = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="mb-8 italic">En vigueur au 04/04/22</div>
      <div className="children:mb-12 whitespace-pre-line">
        <h2 className="text-2xl mb-2">{t('confidentialityArticle1Title')}</h2>
        <p>{t('confidentialityArticle1Content')}</p>

        <h2 className="text-2xl mb-2">{t('confidentialityArticle2Title')}</h2>
        <p>{t('confidentialityArticle2Content')}</p>

        <h2 className="text-2xl mb-2">{t('confidentialityArticle3Title')}</h2>
        <p>{t('confidentialityArticle3Content')}</p>

        <h2 className="text-2xl mb-2">{t('confidentialityArticle4Title')}</h2>
        <p>{t('confidentialityArticle4Content')}</p>

        <h2 className="text-2xl mb-2">{t('confidentialityArticle5Title')}</h2>
        <p>{t('confidentialityArticle5Content')}</p>

        <h2 className="text-2xl mb-2">{t('confidentialityArticle6Title')}</h2>
        <p>{t('confidentialityArticle6Content')}</p>
      </div>
    </>
  );
};

export default ConfidentialityPolicy;
