import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import TabNavItem from './TabNavItem';

type Tab = {
  title: string;
  content: React.ReactNode;
  active?: boolean;
};

type TabsProps = {
  tabs: Tab[];
  fixedNav?: boolean;
};

const Tabs = ({ tabs, fixedNav = true }: TabsProps): JSX.Element => {
  const [currentTab, setCurrentTab] = useState<Tab>(tabs[0]);
  const { content } = currentTab;
  const [fixTop, setFixTop] = useState<boolean>(false);
  const navRef = useRef<HTMLDivElement>(null);

  const SHRINKED_HEADER_HEIGHT = useMemo(() => {
    if (typeof window === 'undefined') return 0;
    return window.innerWidth < 1280 ? 84 : 100;
  }, []);

  useEffect(() => {
    setCurrentTab(tabs.find((t: Tab) => t.active) || tabs[0]);
  }, [tabs]);

  // FIXED NAV
  const checkFixedNav = useCallback(() => {
    setFixTop(window.scrollY > SHRINKED_HEADER_HEIGHT);
  }, [setFixTop]);

  useEffect(() => {
    if (!fixedNav) return null;
    window?.addEventListener('scroll', checkFixedNav);
    return () => window?.removeEventListener('scroll', checkFixedNav);
  }, [fixedNav]);
  // END FIXED NAV

  const selectTab = useCallback((tab: Tab) => {
    setCurrentTab(tab);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div ref={navRef}>
      <div
        className={`
          flex w-full bg-white dark:bg-mainDark pt-[10px]
        `}
        style={
          fixTop
            ? {
                position: 'fixed',
                top: SHRINKED_HEADER_HEIGHT,
              }
            : {}
        }
      >
        {tabs.map((t: Tab) => (
          <TabNavItem
            key={t.title}
            title={t.title}
            isActive={currentTab === t}
            onClick={() => selectTab(t)}
          />
        ))}
      </div>
      <div className={`pt-12 ${fixTop ? 'mt-[320px]' : ''}`}>{content}</div>
    </div>
  );
};

export default Tabs;
