import React from 'react';
import { useTranslation } from 'react-i18next';

const LegalMentions = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="mb-4 italic">En vigueur au 04/04/22</div>
      <div className="children:mb-12 whitespace-pre-line">
        <p>
          Conformément aux dispositions des Articles 6-III et 19 de la Loi
          n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie
          numérique, dite L.C.E.N., il est porté à la connaissance des
          Utilisateurs du site https://charrette.club les présentes mentions
          légales.
        </p>
        <p>
          La connexion et la navigation sur le site (https://charrette.club) par
          l’Utilisateur implique acceptation intégrale et sans réserve des
          présentes mentions légales. Ces dernières sont accessibles sur le site
          à la rubrique « Mentions légales ».
        </p>
        <h2 className="text-2xl mb-2">{t('article1Title')}</h2>
        <p>{t('article1Content')}</p>

        <h2 className="text-2xl mb-2">{t('article2Title')}</h2>
        <p>{t('article2Content')}</p>

        <h2 className="text-2xl mb-2">{t('article3Title')}</h2>
        <p>{t('article3Content')}</p>

        <h2 className="text-2xl mb-2">{t('article4Title')}</h2>
        <p>{t('article4Content')}</p>

        <h2 className="text-2xl mb-2">{t('article5Title')}</h2>
        <p>{t('article5Content')}</p>

        <h2 className="text-2xl mb-2">{t('article6Title')}</h2>
        <p>{t('article6Content')}</p>
      </div>
    </>
  );
};

export default LegalMentions;
