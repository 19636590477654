import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Layout from '@/components/Layout';
import Tabs from '@/components/Tabs';

import LegalMentions from '@/components/Legal/LegalMentions';
import ConfidentialityPolicy from '@/components/Legal/ConfidentialityPolicy';

export default function Home() {
  const { t } = useTranslation();

  return (
    <Layout description="Mentions légales">
      <div className="mt-[150px] lg:mt-[200px]">
        <Tabs
          tabs={[
            {
              title: t('legal'),
              content: <LegalMentions />,
              active: true,
            },
            {
              title: t('confidentialityPolicy'),
              content: <ConfidentialityPolicy />,
            },
          ]}
        />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["legal", "general"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
