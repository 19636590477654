import React from 'react';

type TabNavItemProps = {
  title: string;
  isActive: boolean;
  onClick: () => void;
};

const TabNavItem = ({
  title,
  isActive,
  onClick,
}: TabNavItemProps): JSX.Element => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  <div
    className={`
      font-heading mr-12 text-[20px] lg:text-[30px] cursor-pointer
      ${isActive ? 'text-primary' : ''}
    `}
    onClick={onClick}
  >
    {title}
    <div
      className="w-full h-[8px] bg-primary mt-[12px] transition-transform"
      style={{
        transform: `scaleX(${isActive ? 1 : 0})`,
      }}
    />
  </div>
);

export default TabNavItem;
